import { getPayloadObject, getTelemetryAttributes, ITelemetryContent, TelemetryConstant } from '@msdyn365-commerce-modules/utilities';
import React from 'react';

export const SignInButton: React.FC<{
    text: string;
    href: string;
    ariaLabel: string;
    telemetryContent?: ITelemetryContent;
    imageURL: string;
}> = (props: { text: string; href: string; ariaLabel: string; telemetryContent?: ITelemetryContent; imageURL: string }) => {
    const { text, href, ariaLabel, telemetryContent, imageURL } = props;
    const payLoad = getPayloadObject('click', telemetryContent!, TelemetryConstant.SignIn);
    const attributes = getTelemetryAttributes(telemetryContent!, payLoad);

    return (
        <>
            <a href={href} className='moe-btn moe-btn--primary-blue moe-btn-esl' aria-label={ariaLabel} {...attributes}>
                <span className='moe-btn-esl__left' tabIndex={0}>
                    {text}
                </span>
                <span className='moe-btn-esl__right'>
                    <img className='moe-btn-esl__img' src={imageURL} alt='ESL'></img>
                </span>
            </a>
        </>
    );
};
