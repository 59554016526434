/* eslint-disable react/no-unknown-property */
import React from 'react';
import { IComponent, IComponentProps } from '@msdyn365-commerce/core';
import { generateImageUrl } from '@msdyn365-commerce-modules/retail-actions';
import { IKwrHomePageLanguageAttributes } from '../modules/moe-kwr-homepage/moe-kwr-homepage.view';

export interface ICategoryModalComponentProps extends IComponentProps<{}> {
    className?: string;
    index: number;
    menuName?: string;
    subMenuItems: any;
    renderButton: any;
    buttonClassname: string;
    isModalOpen: boolean;
    btnCloseClick: () => void;
    KwrHomePageLanguageAttributes?: IKwrHomePageLanguageAttributes;
    preferredLanguage?: string;
}

export interface ICategoryModalComponent extends IComponent<ICategoryModalComponentProps> {}

export const CategoryModal: React.FC<ICategoryModalComponentProps> = ({
    isModalOpen,
    menuName,
    buttonClassname,
    subMenuItems,
    context,
    btnCloseClick,
    KwrHomePageLanguageAttributes,
    preferredLanguage
}) => {
    // const [isModalOpen, setIsModalOpen] = React.useState(false);

    const category = subMenuItems;

    const btnMenuClick = () => {
        btnCloseClick();
    };

    return (
        <>
            <li className={isModalOpen ? 'mega mega--atua js-active active' : 'mega mega--atua js-active'} id='mega--atua'>
                <div className='menu-close'>
                    <div className='close-text' data-className='active' data-toggle='.mega--atua'>
                        {KwrHomePageLanguageAttributes?.closeText}
                    </div>
                    <button
                        className='modal-button-close fa-times'
                        data-className='active'
                        data-toggle='.mega--atua'
                        onClick={() => btnMenuClick()}
                    >
                        <span className='sr-only'>Close</span>
                    </button>
                </div>
                <div className='mega__home-icon'></div>

                <h2 className='mega__title'>{KwrHomePageLanguageAttributes?.atuaTabName}</h2>
                <div className='mega__menu-wrapper--atuas'>
                    <ul className='mega__menu-list mega__menu-list--atua'>
                        {category &&
                            category.length > 0 &&
                            category?.map((categoryItem: any) => {
                                return renderMenuItem(categoryItem);
                            })}
                    </ul>
                </div>
            </li>
        </>
    );

    function renderMenuItem(categoryItem: any) {
        const categoryImageUrl = generateImageUrl(`${categoryItem.imageSource}`, context.actionContext.requestContext.apiSettings);
        return (
            <li className='mega__menu-item mega__menu-item--atua'>
                <a
                    tabIndex={2}
                    className='mega__item-link mega__item-link--atua'
                    href={categoryItem.linkURL}
                    title={`Go to '${categoryItem.linkText}' page`}
                >
                    <span className='mega__item-image-wrapper'>
                        <img src={categoryImageUrl} alt='ranginui Atua for mega menu v2' className='mega__item-image'></img>
                    </span>
                    {categoryItem.linkText}
                </a>
            </li>
        );
    }
};
