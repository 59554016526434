/* eslint-disable react/no-unknown-property */
import React from 'react';
import { IComponent, IComponentProps } from '@msdyn365-commerce/core';
import { IKwrHomePageLanguageAttributes } from '../modules/moe-kwr-homepage/moe-kwr-homepage.view';

export interface IEngageModalComponentProps extends IComponentProps<{}> {
    className?: string;
    index: number;
    menuName?: string;
    subMenuItems: any;
    renderButton: any;
    buttonClassname: string;
    isModalOpen: boolean;
    btnCloseClick: () => void;
    KwrHomePageLanguageAttributes?: IKwrHomePageLanguageAttributes;
    preferredLanguage?: string;
}

export interface IEngageModalComponent extends IComponent<IEngageModalComponentProps> {}

export const EnagageModal: React.FC<IEngageModalComponentProps> = ({
    isModalOpen,
    menuName,
    buttonClassname,
    subMenuItems,
    context,
    btnCloseClick,
    KwrHomePageLanguageAttributes,
    preferredLanguage
}) => {
    const category = subMenuItems?.subMenus;

    const btnMenuClick = () => {
        btnCloseClick();
    };

    return (
        <>
            <li className={isModalOpen ? 'mega mega--newsEvents js-active active' : 'mega mega--newsEvents'}>
                <div className='menu-close'>
                    <div className='close-text' data-class='active' data-toggle='.mega--newsEvents' data-toggled='false'>
                        {KwrHomePageLanguageAttributes?.closeText}
                    </div>
                    <button
                        className='modal-button-close fa-times'
                        data-className='active'
                        data-toggle='.mega--newsEvents'
                        data-toggled='false'
                        onClick={() => btnMenuClick()}
                    >
                        <span className='sr-only'>Close</span>
                    </button>
                </div>
                <div className='mega__home-icon'></div>

                <h2 className='mega__title'>{KwrHomePageLanguageAttributes?.engageTabName}</h2>
                <div className='mega__menu-wrapper mega__menu-wrapper--news-events'>
                    <ul className='mega__menu-list mega__menu-list--news-events'>
                        <li className='mega__menu-item mega__menu-item--news-events'>
                            {category &&
                                category.length > 0 &&
                                category?.map((categoryItem: any) => {
                                    return (
                                        <>
                                            <h2 className='mega__menu-item-title'>{categoryItem.linkText}</h2>
                                            {renderSubMenuItems(categoryItem)}
                                        </>
                                    );
                                })}
                        </li>
                        <li className='mega__menu-item mega__menu-item--news-events'></li>
                    </ul>
                </div>
            </li>
        </>
    );

    function renderSubMenuItems(subMenuItem: any) {
        return (
            <>
                {subMenuItem &&
                    subMenuItem.subMenus &&
                    subMenuItem.subMenus.length > 0 &&
                    subMenuItem.subMenus?.map((subMenu: any, idx: number) => {
                        return (
                            <a key={idx} tabIndex={3} href={subMenu?.linkUrl?.destinationUrl} className='mega__item-link'>
                                {subMenu.linkText}
                            </a>
                        );
                    })}
            </>
        );
    }
};
