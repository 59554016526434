/* eslint-disable react/no-unknown-property */
import React from 'react';
import { IComponent, IComponentProps } from '@msdyn365-commerce/core';
import { IKwrHomePageLanguageAttributes } from '../modules/moe-kwr-homepage/moe-kwr-homepage.view';

export interface ICurriculaModalComponentProps extends IComponentProps<{}> {
    className?: string;
    index: number;
    menuName?: string;
    subMenuItems: any;
    renderButton: any;
    isModalOpen: boolean;
    btnCloseClick: () => void;
    KwrHomePageLanguageAttributes?: IKwrHomePageLanguageAttributes;
    preferredLanguage?: string;
}

export interface ICurriculaModalComponent extends IComponent<ICurriculaModalComponentProps> {}

export const CurriculaModal: React.FC<ICurriculaModalComponentProps> = ({
    isModalOpen,
    menuName,
    subMenuItems,
    context,
    btnCloseClick,
    KwrHomePageLanguageAttributes,
    preferredLanguage
}) => {
    const category = subMenuItems?.subMenus;

    const btnMenuClick = () => {
        btnCloseClick();
    };

    return (
        <>
            <li className={isModalOpen ? 'mega mega--learningAreas js-active active' : 'mega mega--learningAreas'}>
                <div className='menu-close'>
                    <div className='close-text' data-className='active' data-toggle='.mega--learningAreas' data-toggled='false'>
                        {KwrHomePageLanguageAttributes?.closeText}
                    </div>
                    <button
                        className='modal-button-close fa-times'
                        data-class='active'
                        data-toggle='.mega--learningAreas'
                        data-toggled='false'
                        onClick={() => btnMenuClick()}
                    >
                        <span className='sr-only'>Close</span>
                    </button>
                </div>
                <div className='mega__home-icon'></div>

                <h2 className='mega__title'>{KwrHomePageLanguageAttributes?.curriculaTabName}</h2>
                <div className='mega__menu-wrapper mega__menu-wrapper--curricula'>
                    <ul className='mega__menu-list mega__menu-list--curricula'>
                        {category &&
                            category.length > 0 &&
                            category?.map((categoryItem: any) => {
                                return renderMenuItems(categoryItem);
                            })}
                    </ul>
                </div>
            </li>
        </>
    );

    function renderMenuItems(menuItem: any) {
        return (
            <>
                <li className='mega__menu-item mega__menu-item--curricula'>
                    <h2 className='mega__menu-item-title'>{menuItem.linkText}</h2>
                    {renderSubMenuItems(menuItem)}
                </li>
            </>
        );
    }

    function renderSubMenuItems(subMenuItem: any) {
        return (
            <>
                {subMenuItem &&
                    subMenuItem.subMenus &&
                    subMenuItem.subMenus.length > 0 &&
                    subMenuItem.subMenus?.map((subMenu: any, idx: number) => {
                        return (
                            <a
                                key={idx}
                                tabIndex={4}
                                href={subMenu?.linkUrl?.destinationUrl}
                                className='mega__item-link mega__item-link--marau'
                            >
                                {subMenu?.image?.src && (
                                    <img src={subMenu?.image?.src} alt='img' aria-label={subMenu?.image?.altText}></img>
                                )}
                                <span className='mega__item-link-text'>{subMenu.linkText}</span>
                            </a>
                        );
                    })}
            </>
        );
    }
};
